import baseTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const theme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    modes: {
      dark: {
        ...baseTheme.colors.modes.dark,
        background: "#1c1c1e",
      },
    },
  },
};

export default theme;